import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const BaseCarousel = forwardRef(
  (
    { items, options, handleSlideChange, currentIdx, className = '' },
    forwardRef
  ) => {
    const [currentSlide, setCurrentSlide] = useState(currentIdx || 0);

    useEffect(() => {
      if (handleSlideChange) handleSlideChange(currentSlide);
    }, [currentSlide]);

    const handleNext = () => {
      setCurrentSlide((prevIndex) => (prevIndex + 1) % items.length);
    };

    const handlePrev = () => {
      setCurrentSlide(
        (prevIndex) => (prevIndex - 1 + items.length) % items.length
      );
    };

    const updateCurrentSlide = (index) => {
      if (currentSlide !== index) {
        setCurrentSlide(index);
      }
    };

    useImperativeHandle(forwardRef, () => ({
      handleNext,
      handlePrev,
      currentSlide,
    }));

    return (
      <Carousel
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        className={className}
        {...options}
      >
        {items.map((item) => (
          <div key={item.key}>{item.component}</div>
        ))}
      </Carousel>
    );
  }
);

export default BaseCarousel;
