import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { BANNER_DATA } from '../../../../commons/Constants';
import BaseCarousel from '../../../../commons/components/ui/BaseCarousel/BaseCarousel';
import styles from './BannerCarousal.module.scss';
import BannerImage from './BannerImage';
import Overlay from './Overlay';
import OverlayPager from './OverlayPager';

function BannerCarousal() {
  const overlayContainerRef = useRef(null);
  const baseCarouselRef = useRef(null);
  const [marginLeft, setMarginLeft] = useState(0);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const updateContainerMargin = () => {
    const parentWidth = window.innerWidth;
    if (overlayContainerRef?.current?.clientWidth) {
      const containerWidth = overlayContainerRef.current.clientWidth;
      const leftMargin = (parentWidth - containerWidth) / 2;
      setMarginLeft(leftMargin);
    }
  };

  useEffect(() => {
    updateContainerMargin();
    window.addEventListener('resize', updateContainerMargin);

    return () => {
      window.removeEventListener('resize', updateContainerMargin);
      updateContainerMargin();
    };
  }, []);

  const next = () => {
    if (baseCarouselRef.current) baseCarouselRef.current.handleNext();
  };
  const prev = () => {
    if (baseCarouselRef.current) baseCarouselRef.current.handlePrev();
  };

  const handleSlideChange = (index) => setCurrentSlideIdx(index);

  const isMobile = useMediaQuery('(max-width:880px)');
  const slides = BANNER_DATA.map((item) => {
    return {
      key: item.id,
      component: (
        <BannerImage
          img={item.img}
          mobileImg={item.mobileImage}
          tabImg={item.tabImage}
          altText={item.title1}
        ></BannerImage>
      ),
    };
  });

  return (
    <div className={styles.HomePageCarousal}>
      <BaseCarousel
        handleSlideChange={handleSlideChange}
        ref={baseCarouselRef}
        items={slides}
        options={getConfigurableProps()}
      />

      <div ref={overlayContainerRef} className={styles.overlayPosition}>
        <Overlay
          leftBtn={prev}
          rightBtn={next}
          data={BANNER_DATA[currentSlideIdx]}
          currentSlide={currentSlideIdx + 1}
          totalSlideCount={BANNER_DATA.length}
        ></Overlay>
      </div>
      <div
        className={styles.overlayPagerPosition}
        style={{
          marginLeft: isMobile ? `${marginLeft}px` : '0px',
          marginBottom: '15px',
          left: isMobile ? '0px' : '3%',
        }}
      >
        <OverlayPager
          leftBtn={prev}
          rightBtn={next}
          current={currentSlideIdx + 1}
          end={BANNER_DATA.length}
        />
      </div>
    </div>
  );
}

export default BannerCarousal;

const getConfigurableProps = () => ({
  showArrows: false,
  showStatus: false,
  showIndicators: false,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  stopOnHover: true,
  swipeable: false,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  interval: 2000,
  transitionTime: 500,
  swipeScrollTolerance: 5,
});
