import { Rating } from '@mui/material';
import { ContainedButton } from '../../../../commons/hoc';
import palette from '../../../../src/styles/v5/palette';
import styles from './BannerCarousal.module.scss';
import Router from 'next/router';

export default function Overlay({ data }) {
  return (
    <div className={styles.overlayContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.content}>
          <h4 className={styles.title1}>{data.title1}</h4>
          <h5
            className={styles.title2}
            dangerouslySetInnerHTML={{
              __html: data.title2,
            }}
          ></h5>

          {data?.isProduct && data?.rating && (
            <div className={styles.ratingContainer}>
              <Rating
                sx={{
                  '& .MuiRating-iconFilled': {
                    color: palette.secondary.light,
                  },
                  '& .Mui-disabled': {
                    color: palette.secondary.light,
                  },
                }}
                size="small"
                value={data?.rating}
              />
              <h5 className={styles.totalReviewCount}>{`(${data.reviews})`}</h5>
            </div>
          )}

          {data?.isProduct && (
            <h4 className={styles.productOfferText}>{data.productOfferText}</h4>
          )}
          <h4 className={styles.description}>{data.description}</h4>

          <div className={styles.buttonGroup}>
            <div className={styles.containedButton}>
              <ContainedButton
                btnType="light"
                arrow={true}
                onClick={() => {
                  Router.push(
                    {
                      pathname: data.isProduct
                        ? '/products/relief-factor'
                        : '/',
                      hash: data.isProduct ? 'buy' : 'productShowCase',
                    },
                    undefined,
                    { scroll: false }
                  );
                }}
              >
                {data.isProduct ? 'Add to Cart' : 'Shop Now'}
              </ContainedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
