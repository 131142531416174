import Image from 'next/image';
import styles from './BannerCarousal.module.scss';
import imgLoader from '../../../../imgloader';

export default function BannerImage({ altText, img, mobileImg, tabImg }) {
  return (
    <div className={styles.BannerImageContainer}>
      <Image
        alt={altText}
        src={mobileImg}
        layout="fill" // Fill the container
        objectFit="cover" // Maintain aspect ratio and cover the container
        // objectPosition="bottom" // Adjust as needed
        loader={imgLoader}
        priority
        placeholder="blur"
        className={styles.mobileSliderImage}
      />
      <Image
        alt={altText}
        src={tabImg}
        layout="fill"
        objectFit="cover"
        objectPosition="bottom"
        loader={imgLoader}
        priority
        placeholder="blur"
        className={styles.tapSliderImage}
      />
      <Image
        alt={altText}
        src={img}
        layout="fill"
        objectFit="cover"
        objectPosition="bottom"
        loader={imgLoader}
        priority
        placeholder="blur"
        className={styles.desktopSliderImage}
      />
    </div>
  );
}
