import { IconButton } from '@mui/material';
import ArrowIcon from './ArrowIcon';
import styles from './BannerCarousal.module.scss';

export default function OverlayPager({ leftBtn, rightBtn, current, end }) {
  const handleRightBtn = () => {
    if (rightBtn) rightBtn();
  };

  const handleLeftBtn = () => {
    if (leftBtn) leftBtn();
  };

  const Navigation = () => {
    return (
      <div className={styles.pager}>
        <IconButton size="medium" onClick={handleLeftBtn}>
          <ArrowIcon isRotated={true} color={'#FFFFFF'} />
        </IconButton>

        <div className={styles.pageNo}>{`${current} / ${end}`}</div>

        <IconButton size="medium" onClick={handleRightBtn}>
          <ArrowIcon color={'#FFFFFF'} />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      <div className={styles.pagerTabPosition}>
        <Navigation />
      </div>
      <div className={styles.pagerTabPostionMobile}>
        <Navigation />
      </div>
    </>
  );
}
