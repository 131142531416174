export default function ArrowIcon({ color, isRotated }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 2632" transform={`rotate(${isRotated ? '180' : '0'} 6 6)`}>
        <path
          id="Path 181 Copy 6"
          d="M5.82336 11.5042L10.6089 5.96992L5.82336 0.736652"
          stroke={color}
        />
        <path id="Vector 8" d="M10.0221 6.0188L0.15918 6.0188" stroke={color} />
      </g>
    </svg>
  );
}
